export default {
    name: "Ravencoin",
    coin: "RVN",
    scheme: "SOLO",
    account: 'RD6zEfzdFnzuTRXH6hzsYvZXSmaLxbSLTK',
    explorer_url: 'https://blockbook.ravencoin.org',
    coingecko: 'https://www.coingecko.com/en/coins/ravencoin',
    explorer: {
        address: "https://blockbook.ravencoin.org/address/",
        height: "https://blockbook.ravencoin.org/block/",
        hash: "https://blockbook.ravencoin.org/block/",
        tx: "https://blockbook.ravencoin.org/tx/",
    },
    ports: [
        {
            info: "LOW",
            host: "molepool.com",
            port: 5501,
            diff: 0.5,
        },
        {
            info: "MID",
            host: "molepool.com",
            port: 5502,
            diff: 2,
        },
        {
            info: "HIGH",
            host: "molepool.com",
            port: 5503,
            diff: 8,
        }
    ]
};